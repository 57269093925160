exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amazon-js": () => import("./../../../src/pages/amazon.js" /* webpackChunkName: "component---src-pages-amazon-js" */),
  "component---src-pages-d-2-c-js": () => import("./../../../src/pages/d2c.js" /* webpackChunkName: "component---src-pages-d-2-c-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-gutschein-js": () => import("./../../../src/pages/gutschein.js" /* webpackChunkName: "component---src-pages-gutschein-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marktplaetze-js": () => import("./../../../src/pages/marktplaetze.js" /* webpackChunkName: "component---src-pages-marktplaetze-js" */),
  "component---src-pages-vcf-js": () => import("./../../../src/pages/vcf.js" /* webpackChunkName: "component---src-pages-vcf-js" */),
  "component---src-pages-webentwicklung-js": () => import("./../../../src/pages/webentwicklung.js" /* webpackChunkName: "component---src-pages-webentwicklung-js" */)
}

